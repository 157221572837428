<script>
import {
  //Action,
  ContentSection,
  Tooltip,
  MultiSelect,
  Modal,
} from 'epmc-patterns/components/v2'
import { Dropdown } from 'epmc-patterns/components/v3'
import { SECTIONS, PLACES_TO_SEARCH, ARTICLE_SECTION_OPTIONS } from '@/config'
import vClickOutside from 'v-click-outside'
import { getSearchPath, constructHtmlForQuery } from '@/helpers/search'
import { fetchStats } from '@/api'
import { uuid } from 'vue-uuid'

export default {
  components: {
    ContentSection,
    Dropdown,
    Tooltip,
    MultiSelect,
    Modal,
    TipsTooltip: () => import('./components/tooltips/TipsTooltip.vue'),
    FindExactPhraseTooltip: () =>
      import('./components/tooltips/FindExactPhraseTooltip.vue'),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      countLoading: false,
      totalCount: 0,
      query: [],
      rowTerm: null,
      termOrPhrase: null,
      isFindExactPhraseEnabled: false,
      logicalOperator: 'OR',
      sections: SECTIONS.map((section) => section),
      options: [
        {
          text: 'OR',
          value: 'OR',
          selected: true,
        },
        {
          text: 'AND',
          value: 'AND',
          selected: false,
        },
        {
          text: 'NOT',
          value: 'NOT',
          selected: false,
        },
      ],
      placesToSearch: PLACES_TO_SEARCH,
      articleSectionOptions: ARTICLE_SECTION_OPTIONS,
      multiSelectStyle:
        'height: 42px; width: 255px; border: 1px solid #1376ae; caret-color: transparent; cursor:pointer; box-shadow: none;',
      closeEditTermTooltip: false,
      showIntroductionModal: false,
      betaAdvanceSearchDescription:
        'Try this new version (additional filters will be added over time) and give feedback in the exit survey!',
    }
  },
  computed: {
    isAnyItemSelected() {
      return (
        (this.placesToSearch.some((item) => item.selected) ||
          this.articleSectionOptions.some((item) => item.selected)) &&
        this.termOrPhrase
      )
    },
  },
  mounted() {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBetaAdvanceSearch')
    if (!hasVisitedBefore) {
      this.showIntroductionModal = true
    }
  },
  methods: {
    renderQueryHtml(item) {
      return constructHtmlForQuery(item)
    },
    onActionButtonClick() {},
    onTermOrPhraseChange(event) {
      const value = event.target.value
      this.termOrPhrase = value
      if (value === '') {
        this.isFindExactPhraseEnabled = false
      }
      if (/^(['"]).*\1$/.test(value)) {
        this.isFindExactPhraseEnabled = true
      }
    },
    onSectionOpened(sectionTitle) {
      const { sections } = this
      sections.forEach((section) => {
        if (section.title !== sectionTitle) {
          section.visible = false
        }
      })
    },
    onLogicalOperatorChange(operator) {
      const { value } = operator
      this.logicalOperator = value
    },
    onQueryLogicalOperatorChange(operator, id) {
      const rowToUpdate = this.query.find((item) => item.id === id)
      const rowToUpdateIndex = this.query.findIndex((item) => item.id === id)
      rowToUpdate.logicalOperator = operator.value
      this.$set(this.query, rowToUpdateIndex, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
    },
    onFindExactPhraseClick(e) {
      if (e.target.checked) {
        this.termOrPhrase = `"${this.termOrPhrase}"`
        this.isFindExactPhraseEnabled = true
        return
      }
      this.termOrPhrase = this.termOrPhrase.replace(/"/g, '')
      this.isFindExactPhraseEnabled = false
    },
    onPlaceToSearchClick(id) {
      const place = this.placesToSearch.find((item) => item.id === id)
      if (place) {
        this.$set(place, 'selected', !place.selected)
      }
    },
    onArticleSectionOptionsChanged() {
      const isAnyArticleOptionSelected = this.articleSectionOptions.find(
        (item) => item.selected === true
      )

      let updatedStyle = this.multiSelectStyle // Create a temporary variable
      if (isAnyArticleOptionSelected) {
        if (!updatedStyle.includes('background-color: #dfecf5;')) {
          updatedStyle += ' background-color: #dfecf5;'
        }
      } else {
        updatedStyle = updatedStyle.replace('background-color: #dfecf5;', '')
      }
      this.multiSelectStyle = updatedStyle // Assign back to trigger reactivity
    },
    onDeleteRowClick(id) {
      const rowToDelete = this.query.find((item) => item.id === id)
      if (rowToDelete) {
        const index = this.query.findIndex((item) => item.id === id)
        if (index === 0 && this.query.length > 1) {
          const secondRow = this.query[1]
          delete secondRow.logicalOperator
          this.$set(this.query, 1, secondRow)
        }
      }
      this.query = this.query.filter((item) => item.id !== id)
      this.getTotalCount()
    },
    onRowContentChange(event, item) {
      event.preventDefault()
      const updatedTerm = event.target.rowTerm.value
      const { id, term } = item
      const rowToUpdate = this.query.find((item) => item.id === id)
      const index = this.query.findIndex((item) => item.id === id)
      rowToUpdate.term = updatedTerm
      rowToUpdate.query = rowToUpdate.query.replaceAll(
        `(${term})`,
        `(${updatedTerm})`
      )
      this.$set(this.query, index, rowToUpdate)
      this.closeEditTermTooltip = true
      this.countLoading = true
      this.getQueryCount(index, rowToUpdate)
      this.countLoading = true
      this.getTotalCount()
    },
    getLogicalOperatorDropdownClass() {
      const selectedLogicalOperator = this.options.find(
        (option) => option.selected === true
      )

      return selectedLogicalOperator.value.toLowerCase()
    },
    canSubmit() {
      const { query, termOrPhrase } = this
      return query.length > 0 && termOrPhrase.length > 0
    },
    onTopicsOrKeywordsSubmit(e) {
      e.preventDefault()
      const { termOrPhrase, query, logicalOperator } = this
      const placesToSearchQuery = this.createPlacesToSearchQuery(termOrPhrase)
      const articleSectionSearchQuery =
        this.createArticleSectionSearchQuery(termOrPhrase)
      if (placesToSearchQuery && articleSectionSearchQuery) {
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator,
          query: placesToSearchQuery + ' OR ' + articleSectionSearchQuery,
          term: termOrPhrase,
        })
      } else if (placesToSearchQuery) {
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator,
          query: placesToSearchQuery,
          term: termOrPhrase,
        })
      } else if (articleSectionSearchQuery) {
        query.push({
          id: uuid.v1(),
          logicalOperator: query.length > 0 && logicalOperator,
          query: articleSectionSearchQuery,
          term: termOrPhrase,
        })
      }
      this.resetTopicsAndKeywordsForm()
      this.countLoading = true
      const itemIndex = this.query.length - 1
      const rowToUpdate = this.query[this.query.length - 1]
      this.getQueryCount(itemIndex, rowToUpdate)
      this.getTotalCount()
    },
    getQueryCount(itemIndex, rowToUpdate) {
      const response = fetchStats(rowToUpdate.query)
      response
        .then((result) => {
          rowToUpdate.count = result.hitCount
          this.$set(this.query, itemIndex, rowToUpdate)
          this.countLoading = false
        })
        .catch(() => {
          this.countLoading = false
        })
    },
    getTotalCount() {
      const query = this.createSearchQuery(this.query)
      const response = fetchStats(query)
      response
        .then((result) => {
          this.totalCount = result.hitCount
          this.countLoading = false
        })
        .catch(() => {
          this.countLoading = false
        })
    },
    onSearchQueryClick(searchQuery) {
      const finalQuery = searchQuery
        ? searchQuery
        : this.createSearchQuery(this.query)
      const searchRoute = getSearchPath()
      const query = encodeURIComponent(finalQuery || 'SRC:*')
      location.href = `/${searchRoute}?query=${query}`
    },
    onClearQueryClick() {
      this.query = []
    },
    createSearchQuery(data) {
      let searchQuery = ''
      let startingBrackets = ''
      data.map(({ query, logicalOperator }, index) => {
        // Brackets logic to build the query (((a + b) + c) + d)
        if (index > 1) {
          searchQuery += ')'
        }
        if (index > 0) {
          startingBrackets = startingBrackets + '('
          searchQuery += ` ${logicalOperator} ` // Add logical operator if not the first query
        }
        searchQuery += query
        if (index > 0 && index + 1 === this.query.length) {
          searchQuery += ')'
        }
      })
      return `${startingBrackets}${searchQuery}`
    },
    resetTopicsAndKeywordsForm() {
      document.getElementById('topicsAndKeywordsForm').reset()
      this.termOrPhrase = null
      const resetedPlacesToSearch = this.placesToSearch.map((placeToSearch) => {
        return {
          ...placeToSearch,
          selected: false,
        }
      })
      const resetedArticleSectionOptions = this.articleSectionOptions.map(
        (articleSectionOption) => {
          return {
            ...articleSectionOption,
            selected: false,
          }
        }
      )
      this.multiSelectStyle = this.multiSelectStyle.replace(
        'background-color: #dfecf5;',
        ''
      )

      this.placesToSearch = resetedPlacesToSearch
      this.articleSectionOptions = resetedArticleSectionOptions
    },
    createPlacesToSearchQuery(termOrPhrase) {
      let selectedPlacesToSearch = []
      let placesToSearchQuery = ''
      this.placesToSearch.map(({ value, selected }) => {
        if (selected === true) selectedPlacesToSearch.push(value)
      })
      if (
        selectedPlacesToSearch.includes('TITLE') &&
        selectedPlacesToSearch.includes('ABSTRACT')
      ) {
        placesToSearchQuery = `TITLE_ABS:(${termOrPhrase})`
        if (selectedPlacesToSearch.length > 2) {
          placesToSearchQuery = placesToSearchQuery + ' OR '
        }
        const updatedList = selectedPlacesToSearch.filter(
          (item) => item !== 'TITLE' && item !== 'ABSTRACT'
        )
        selectedPlacesToSearch = updatedList
      }
      selectedPlacesToSearch.map((selectedPlace, index) => {
        placesToSearchQuery =
          placesToSearchQuery +
          `${index > 0 ? ' OR ' : ''}${
            selectedPlace ? selectedPlace + ':' : ''
          }(${termOrPhrase})`
      })
      placesToSearchQuery.replace(' ', 'OR')
      return placesToSearchQuery
    },
    createArticleSectionSearchQuery(termOrPhrase) {
      const { articleSectionOptions } = this
      let articleSectionSearchQuery = ''
      articleSectionOptions
        .filter((item) => item.selected === true)
        .map(({ value }, index) => {
          articleSectionSearchQuery =
            articleSectionSearchQuery +
            `${index > 0 ? ' OR ' : ''}${value}:(${termOrPhrase})`
        })
      articleSectionSearchQuery.replace(' ', 'OR')
      return articleSectionSearchQuery
    },
    getSelectedOptionForQuery(logicalOperator) {
      return this.options.find((item) => item.value === logicalOperator)
    },
    exportQuery() {
      const content = this.createSearchQuery(this.query)
      const element = document.createElement('a')
      const file = new Blob([content], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = 'query.txt'
      document.body.appendChild(element) // Append anchor element to the body
      element.click() // Simulate click to trigger download
      document.body.removeChild(element) // Clean up
    },
    closeIntroductionModal() {
      this.showIntroductionModal = false
      localStorage.setItem('hasVisitedBetaAdvanceSearch', 'true')
    },
  },
}
</script>

<template>
  <div ref="scrollContainer" class="advanceSearchContainer">
    <div id="epmc_searchBanner"></div>
    <div class="grid-row" style="flex: 1">
      <div class="col-16">
        <div class="advanceSearchTitle">
          <div>
            <h1>Advanced search - (abbreviated version)</h1>
            <p>
              {{ betaAdvanceSearchDescription }}
            </p>
            <!--<div id="advanceSearchActions">
              <action
                class="advanceSearchAction"
                icon-pos="left"
                @click="onActionButtonClick"
              >
                <i slot="icon" class="fas fa-caret-right" />How to build a query
              </action>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <action
                class="advanceSearchAction"
                icon-pos="left"
                @click="onActionButtonClick"
              >
                Advanced search tips
              </action>
            </div>
			-->
          </div>
        </div>
        <div class="has-sections">
          <content-section
            v-for="section in sections"
            :key="section.id"
            :section="section"
            @opened="onSectionOpened"
          >
            <template v-if="section.id === 'keywordsOrTopics'">
              <div class="">
                <div class="">
                  <form
                    id="topicsAndKeywordsForm"
                    @submit="onTopicsOrKeywordsSubmit"
                  >
                    <h5>1. Enter word(s) or phrase</h5>
                    <div class="termsOrPhraseInput">
                      <dropdown
                        v-if="query.length > 0"
                        :class="[
                          'operatorDropdown',
                          getLogicalOperatorDropdownClass(),
                        ]"
                        :options="options"
                        :with-separator="false"
                        dropdown-icon-type="chevron"
                        @onOptionSelected="onLogicalOperatorChange"
                      />
                      <input
                        v-if="query.length === 0"
                        name="termOrPhrase"
                        :value="termOrPhrase"
                        required
                        @input="onTermOrPhraseChange"
                      />
                      <input
                        v-if="query.length > 0"
                        class="termInput"
                        name="termOrPhrase"
                        :value="termOrPhrase"
                        required
                        @input="onTermOrPhraseChange"
                      />
                    </div>
                    <p class="termsOrPhraseNote">
                      If more than one word is entered, use the guidelines
                      below:
                    </p>
                    <ul>
                      <li>
                        If multiple words are entered as separate ideas, add
                        upper case OR, AND, or NOT between them
                        <tips-tooltip />
                      </li>
                      <li>
                        If multiple words are entered as a phrase, select
                        <span id="exactPhrase"
                          ><input
                            type="checkbox"
                            :checked="isFindExactPhraseEnabled"
                            :disabled="!termOrPhrase"
                            @input="onFindExactPhraseClick"
                          />
                          <find-exact-phrase-tooltip />
                        </span>
                      </li>
                    </ul>

                    <h5 style="margin-top: 22px; margin-bottom: 0">
                      2. Select one or more places to search
                    </h5>
                    <div class="placesToSearch">
                      <div
                        v-for="searchPlace in placesToSearch"
                        :key="searchPlace.id"
                        :class="[
                          'checkbox',
                          searchPlace.selected ? 'selectedCheckbox' : '',
                        ]"
                        @click="onPlaceToSearchClick(searchPlace.id)"
                      >
                        {{ searchPlace.label }}
                        <sup>
                          <tooltip v-if="searchPlace.information">
                            <i slot="trigger" class="far fa-question-circle" />
                            <b>{{ searchPlace.label }}:</b>
                            {{ searchPlace.information }}
                          </tooltip>
                        </sup>
                      </div>
                      <div class="multiSelectContainer">
                        <multi-select
                          class="articleSectionMultiSelect"
                          input-id="multi-select"
                          :options="articleSectionOptions"
                          :enable-tags="false"
                          placeholder="Article Sections"
                          :multi-select-style="multiSelectStyle"
                          variant="advanceSearch"
                          @onOptionChanged="onArticleSectionOptionsChanged"
                        />
                      </div>
                    </div>
                    <div class="submitButton">
                      <button type="submit" :disabled="!isAnyItemSelected">
                        <i class="fa fa-plus"></i>&nbsp;
                        <span v-if="query.length > 0"> Add </span>
                        <span v-else>Start building my query</span>
                      </button>
                    </div>
                    <div></div>
                  </form>
                </div>
              </div>
            </template>
            <template v-if="section.id === 'dateOrTypeOrSourceOrId'">
              To be added later
            </template>
          </content-section>
        </div>
      </div>
    </div>
    <div class="searchQuerySection">
      <div class="grid-row">
        <div class="col-16">
          <div class="searchQueryTitle">
            <h3>Search Query:</h3>
          </div>
        </div>
      </div>
      <div class="searchQueryRowsContainer">
        <div class="grid-row">
          <div class="col-16">
            <div v-if="query.length > 0" class="searchQueryActions">
              <div class="searchButtonContainer">
                <button class="regular" @click="onSearchQueryClick()">
                  <i class="fa fa-search"></i
                  ><span id="search-btn-text">&nbsp;Search</span>
                </button>
              </div>
              <div>
                <a @click="exportQuery">Export</a> |
                <a @click="onClearQueryClick">Clear all</a>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-row">
          <div class="col-16">
            <div class="searchQueryRows">
              <p v-if="query.length === 0" class="noQueryText">
                Enter a search query above.
              </p>
              <div
                v-for="(item, index) in query"
                :key="item.id"
                class="searchQueryRowContainer"
              >
                <div style="display: flex; width: 100%; align-items: center">
                  <div class="searchQueryRow col-13">
                    <div
                      v-if="index === 0 && query.length > 1"
                      style="display: flex"
                    >
                      <div v-for="(item, queryIndex) in query" :key="item.id">
                        <div v-if="queryIndex > 0" class="queryBracket">(</div>
                      </div>
                    </div>
                    <dropdown
                      v-if="item.logicalOperator"
                      :class="[
                        'operatorDropdown',
                        'operatorDropdownSmall',
                        item.logicalOperator.toLowerCase(),
                      ]"
                      :options="options"
                      :with-separator="false"
                      :selected="
                        getSelectedOptionForQuery(item.logicalOperator)
                      "
                      dropdown-icon-type="chevron"
                      @onOptionSelected="
                        onQueryLogicalOperatorChange($event, item.id)
                      "
                    />
                    <span
                      ref="editableContent"
                      class="queryHtmlContainer"
                      v-html="renderQueryHtml(item)"
                    ></span>
                    <div v-if="index > 0" class="queryBracket endBracket">
                      )
                    </div>
                  </div>
                  <tooltip
                    trigger="click"
                    placement="top"
                    :close="closeEditTermTooltip"
                  >
                    <i
                      slot="trigger"
                      style="margin-right: 8px; font-size: 14px"
                      class="fas fa-pen"
                      @click="closeEditTermTooltip = false"
                    />
                    <b><p style="margin-top: 0">Edit Term</p></b>
                    <form @submit="onRowContentChange($event, item)">
                      <input
                        required
                        name="rowTerm"
                        style="margin-right: 8px"
                        :value="item.term"
                      />
                      <button
                        style="
                          background: white;
                          border: none;
                          padding: 0;
                          margin: 0;
                          min-width: 10px;
                        "
                      >
                        <i type="submit" class="fas fa-check" />
                      </button>
                    </form>
                  </tooltip>
                  <i class="fas fa-times" @click="onDeleteRowClick(item.id)" />
                </div>
                <p
                  :class="['count', countLoading ? 'disabled' : '']"
                  @click="onSearchQueryClick(item.query)"
                >
                  {{ item.count ? item.count.toLocaleString() : 0 }}
                </p>
              </div>
            </div>
            <div id="totalResults" class="totalCount">
              <span v-if="query.length > 0" @click="onSearchQueryClick()">
                RESULTS:
                {{ totalCount.toLocaleString() }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="showIntroductionModal" @close="closeIntroductionModal">
      <template slot="title">Impact the usability of Advanced search!</template>
      {{ betaAdvanceSearchDescription }}
      <div style="text-align: right; margin-top: 20px">
        <button @click="closeIntroductionModal">Ok</button>
      </div>
    </modal>
  </div>
</template>

<style scoped lang="scss">
.advanceSearchContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: left;
  .advanceSearchTitle {
    margin-top: $base-unit * 10;
    padding-left: $base-unit * 5;
    padding-right: $base-unit * 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      margin-top: $base-unit * 2;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.79px;
      text-align: left;
      color: $epmc-darker-grey;
    }
    #advanceSearchSmTitle {
      display: none;
    }
    #advanceSearchActions {
      display: flex;
      align-items: center;
      .advanceSearchAction {
        display: flex;

        i {
          font-size: $base-unit * 5;
        }
      }
    }
  }
  .close-icon {
    float: right;
    i {
      font-size: $base-unit * 5;
    }
  }

  .has-sections {
    .col-12 {
      margin-left: 0;
    }
  }

  .termsOrPhraseInput {
    display: flex;
    height: ($base-unit * 9) + 2;
    margin-left: $base-unit * 5;
    .termInput,
    input {
      border: 1px solid #a7a6a6;
      width: 100%;
    }
    .termInput {
      border-left: none;
    }
  }
  .termsOrPhraseNote {
    margin: 0;
    margin-top: $base-unit + 2;
    margin-left: $base-unit * 5;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: lighten($epmc-darker-grey, 10%);
  }
  ul {
    margin: 0px 0 16px;
    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 21.79px;
      color: lighten($epmc-darker-grey, 10%);
      margin: 3px 0;
    }
  }
  #exactPhrase {
    color: $epmc-darker-blue;
    cursor: pointer;
    input {
      accent-color: $epmc-darker-blue;
    }
  }

  .placesToSearch {
    display: flex;
    flex-wrap: wrap;
    margin-left: $base-unit * 5;
    .checkbox {
      border: 1px solid #1376ae;
      padding: $base-unit * 2 $base-unit * 6;
      color: #1376ae;
      font-weight: 600;
      font-size: $base-unit * 4;
      cursor: pointer;
      margin-right: $base-unit * 2;
      margin-top: $base-unit + 2;
      i {
        font-size: $base-unit * 3;
      }
    }
    .checkbox:last-of-type {
      border-right: 1px solid #1376ae;
    }
    .selectedCheckbox {
      background-color: $epmc-light-blue;
    }
    .multiSelectContainer {
      margin-top: $base-unit + 2;
    }
  }

  .submitButton {
    margin-top: $base-unit * 8;
    margin-left: $base-unit * 5;
  }

  h5,
  h6 {
    margin-bottom: $base-unit + 2;
    font-style: normal;
  }
  .searchQuerySection {
    margin-top: $base-unit * 5;
    width: 100%;
    bottom: 0;
    .searchQueryTitle {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      width: $base-unit * 43 + 3;
      background-color: $epmc-light-blue;
      h3 {
        margin: 0;
      }
    }
    .searchQueryRowsContainer {
      background-color: $epmc-light-blue;
      padding: 20px;
      padding-bottom: 100px;
      .searchQueryActions {
        padding-left: $base-unit * 5;
        .searchButtonContainer {
          display: flex;
          justify-content: flex-end;
        }
        a {
          font-size: $base-unit * 4;
          font-weight: 600;
          line-height: 21.79px;
        }
      }
      .searchQueryRows {
        padding: ($base-unit * 5) 0 ($base-unit * 4) ($base-unit * 5);
        .noQueryText {
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
          line-height: 21.79px;
          color: #aaa2a2;
        }
        .searchQueryRowContainer {
          display: flex;
          align-items: center;
          .searchQueryRow {
            display: flex;
            align-items: center;
            min-height: $base-unit * 8 + 1;
            background: $epmc-white;
            border: 1px solid lighten($epmc-darker-grey, 50%);
            padding-right: $base-unit;
            margin-right: $base-unit * 2;
            position: relative;
            span {
              padding-left: $base-unit;
            }
            .queryHtmlContainer {
              white-space: nowrap;
              overflow-x: auto;
              scrollbar-width: thin;
            }
            .queryBracket {
              border: 1px solid $epmc-darker-blue;
              color: $epmc-darker-blue;
              background: white;
              font-size: $base-unit * 4 + 2;
              font-weight: 600;
              line-height: 21.79px;
              padding: $base-unit + 1;
            }
            .endBracket {
              position: absolute;
              right: 0;
            }
          }
          i {
            cursor: pointer;
            color: #1376ae;
            font-size: $base-unit * 4;
          }
          .count {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            color: #1376ae;
          }
          .disabled {
            color: lighten($epmc-darker-grey, 45%);
            pointer-events: none;
          }
          &:last-of-type {
            border-bottom: 1px solid lighten($epmc-darker-grey, 50%);
            padding-bottom: $base-unit * 2;
          }
        }
      }
      .totalCount {
        text-align: right;
        span {
          cursor: pointer;
          font-size: $base-unit * 4;
          font-weight: 600;
          line-height: $base-unit * 4 + 2;
          color: #20699c;
        }
      }
    }
  }
  .operatorDropdown {
    background: $epmc-light-green;
    width: $base-unit * 28 !important;
  }
  .operatorDropdownSmall {
    width: $base-unit * 21 !important;
    font-size: $base-unit * 3 + 2;
  }
  .or {
    background: $epmc-light-green;
  }
  .and {
    background-color: #f7fcba;
  }
  .not {
    background-color: #f2e8e8;
  }
}

@media screen and (max-width: $breakpoint-smallest) {
  .advanceSearchTitle {
    align-items: unset !important;
    #advanceSearchSmTitle {
      display: block !important;
    }
  }
  #advanceSearchActions {
    align-items: unset !important;
    .advanceSearchAction {
      font-size: $base-unit * 3;
    }
  }
  .close-icon {
    i {
      font-size: $base-unit * 4 !important;
    }
  }
  .operatorDropdown,
  .termsOrPhraseInput,
  .termsOrPhraseNote,
  .placesToSearch,
  .submitButton {
    margin-left: 0 !important;
  }
}
</style>
